<template>
  <div>
    <router-view :key="$route.params.id" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'payrollHome',
  // mounted() {
  //   this.userCheck()
  // },
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  methods: {
    // userCheck() {
    //   if (this.userProfile && this.userProfile.evereeOnboardingComplete) {
    //     this.$router.push('/account/payroll/address')
    //   }
    // },
  },
   components: {
    Loader,
  },
}
</script>